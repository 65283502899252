<template>
<div>
    <CRow v-show="mostrarEmpresas">
            <CCol md="3">
                <multiselect 
                    v-show="mostrarEmpresas"
                    class="m-0 d-inline-block mb-2" 
                    v-model="empresaSelected"
                    deselect-label="" 
                    label="nombreEmpresa" 
                    placeholder="Mi Empresa" 
                    select-label=""
                    selectedLabel=""
                    :options="empresasTrabajador" 
                    :searchable="true"
                    :loading="isLoadingEmpresas"
                    @select="seleccionarEmpresa">
                </multiselect>
            </CCol>
        </CRow>
            
            <InfoTrabajadorComponente
                :infoTrabajador="infoTrabajador"
                :isLoadingInfoPersonal="isLoadingInfo"
                :isLoadingInfoLaboral="isLoadingInfo"
                />
        
    </div>
</template>

<script>

    import cons from '@/const'
    import axios from "axios"
    import Multiselect from 'vue-multiselect';

    
    import InfoTrabajadorComponente from "@/views/trabajador/ComponenteInfoTrabajador.vue";

    import { freeSet } from "@coreui/icons";
    export default {
      name: "PerfiTrabajador",
      freeSet,
      components: {
            Multiselect,
            InfoTrabajadorComponente
        },
        data() {
            return {
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                
                //nombreUsuario: JSON.parse(this.$cookie.get("userLogginToken")).nombreUsuario,
                //tipoUsuario: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                //nombreTrab: JSON.parse(this.$cookie.get('userLogginToken')).nombreTrabajador,
                //apellidoTrab: JSON.parse(this.$cookie.get('userLogginToken')).apellidoTrabajador,
                //correoTrab: JSON.parse(this.$cookie.get('userLogginToken')).mailTrabajador,
                //rutTrab: JSON.parse(this.$cookie.get('userLogginToken')).rutTrabajador,
                infoTrabajador: null,
                
                isLoadingEmpresas: false,
                mostrarEmpresas: false,
                empresasTrabajador: [],
                empresaSelected: {},
                isCollapsedPersonal: true,
                isCollapsedLaboral: true,

                isLoadingInfo: false,

            };
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

            this.getEmpresasTrabajador()

        },
        methods: {
            obtenerInfo: function() {
                this.isLoadingInfo = true;
                var url = this.urlBase + "/get_info_trabajador"

                axios({
                    method: "POST",
                    url: url,
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    "data": {
                        "info": {
                            "tipoBd": "REMU",
                            "idEmpresa": this.empresaSelected.idEmpresa,
                        }
                    }
                    })
                    .then((result) => {
                        this.infoTrabajador = result.data.infoTrabajador;
                        this.isLoadingInfo = false;
                    },
                    (error) => {
                    
                        this.isLoadingInfo = false;
                    }
                    )
                    .finally(() => {
                        this.isLoadingInfo = false;
                    
                    });

            },
            getEmpresasTrabajador: function(){
                this.isLoadingEmpresas = true;
                var url = this.urlBase + "/get_empresas_trabajador"
                axios({
                    url: url,
                    method: 'POST',
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    
                    this.empresasTrabajador = response.data;
                    if(this.empresasTrabajador.length > 1){
                        this.mostrarEmpresas = true;
                    }
                    this.seleccionarEmpresa(this.empresasTrabajador[0])

                    this.isLoadingEmpresas = false;

                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },
            seleccionarEmpresa: function(empresa){
                this.empresaSelected = empresa;
                this.obtenerInfo();
            },
        }
    };
    </script>
    